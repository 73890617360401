/*eslint-disable*/ module.exports = {
  messages: {
    '$ <0/>': '$ <0/>',
    '$-': '$-',
    '${0}': ['$', ['0']],
    '(${0})': ['($', ['0'], ')'],
    '(Max)': '(Max)',
    '(View on Explorer)': '(View on Explorer)',
    '(claim)': '(claim)',
    '(clear all)': '(clear all)',
    '(edit)': '(edit)',
    '- Remove recipient': '- Remove recipient',
    '0 UNI / week': '0 UNI / week',
    '25%': '25%',
    '50%': '50%',
    '75%': '75%',
    '<0/> All Proposals': '<0/> All Proposals',
    '<0/> Votes': '<0/> Votes',
    '<0>Account analytics and accrued fees</0><1> ↗ </1>': '<0>Account analytics and accrued fees</0><1> ↗ </1>',
    '<0>Current Price:</0><1><2/></1><3>{0} per {1}</3>': [
      '<0>Current Price:</0><1><2/></1><3>',
      ['0'],
      ' per ',
      ['1'],
      '</3>',
    ],
    '<0>Tip:</0> Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.':
      '<0>Tip:</0> Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.',
    '<0>Tip:</0> Select an action and describe your proposal for the community. The proposal cannot be modified after submission, so please verify all information before submitting. The voting period will begin immediately and last for 7 days. To propose a custom action, <1>read the docs</1>.':
      '<0>Tip:</0> Select an action and describe your proposal for the community. The proposal cannot be modified after submission, so please verify all information before submitting. The voting period will begin immediately and last for 7 days. To propose a custom action, <1>read the docs</1>.',
    "<0>Tip:</0> Use this tool to find v2 pools that don't automatically appear in the interface.":
      "<0>Tip:</0> Use this tool to find v2 pools that don't automatically appear in the interface.",
    '<0>Tip:</0> When you add liquidity, you will receive pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.':
      '<0>Tip:</0> When you add liquidity, you will receive pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.',
    '<0>Unlock voting</0> to prepare for the next proposal.': '<0>Unlock voting</0> to prepare for the next proposal.',
    '<0>{0}</0>': ['<0>', ['0'], '</0>'],
    '<0>🎉 </0>Welcome to team Unicorn :) <1>🎉</1>': '<0>🎉 </0>Welcome to team Unicorn :) <1>🎉</1>',
    'A minimum threshold of 0.25% of the total UNI supply is required to submit proposals':
      'A minimum threshold of 0.25% of the total UNI supply is required to submit proposals',
    About: 'About',
    Accept: 'Accept',
    Account: 'Account',
    Active: 'Active',
    Add: 'Add',
    'Add <0/> and <1/> to L7DEX Swap': 'Add <0/> and <1/> to L7DEX Swap',
    'Add Delegate +': 'Add Delegate +',
    'Add Liquidity': 'Add Liquidity',
    'Add V2 Liquidity': 'Add V2 Liquidity',
    'Add liquidity.': 'Add liquidity.',
    'Add more liquidity': 'Add more liquidity',
    'Add {0} to Metamask <0/>': ['Add ', ['0'], ' to Metamask <0/>'],
    'Add {0}-{1} liquidity': ['Add ', ['0'], '-', ['1'], ' liquidity'],
    'Add {0}/{1} V3 liquidity': ['Add ', ['0'], '/', ['1'], ' V3 liquidity'],
    'Added {0}': ['Added ', ['0']],
    'Address has no available claim': 'Address has no available claim',
    Against: 'Against',
    'Allow LP token migration': 'Allow LP token migration',
    'Allow high price impact trades and skip the confirm screen. Use at your own risk.':
      'Allow high price impact trades and skip the confirm screen. Use at your own risk.',
    'Allow the L7DEX Protocol to use your {0}': ['Allow the L7DEX Protocol to use your ', ['0']],
    Allowed: 'Allowed',
    'Allowed Slippage': 'Allowed Slippage',
    Amount: 'Amount',
    'An error occurred when trying to execute this swap. You may need to increase your slippage tolerance. If that does not work, there may be an incompatibility with the token you are trading.':
      'An error occurred when trying to execute this swap. You may need to increase your slippage tolerance. If that does not work, there may be an incompatibility with the token you are trading.',
    Approve: 'Approve',
    'Approve Token': 'Approve Token',
    'Approve {0}': ['Approve ', ['0']],
    Approved: 'Approved',
    Approving: 'Approving',
    'Approving {0}': ['Approving ', ['0']],
    Arbiscan: 'Arbiscan',
    'Arbitrum Bridge': 'Arbitrum Bridge',
    'Arbitrum is in Beta and may experience downtime. During downtime, your position will not earn fees and you will be unable to remove liquidity. <0>Read more.</0>':
      'Arbitrum is in Beta and may experience downtime. During downtime, your position will not earn fees and you will be unable to remove liquidity. <0>Read more.</0>',
    'Are you sure?': 'Are you sure?',
    'At least {0} {1} and {2} {3} will be refunded to your wallet due to selected price range.': [
      'At least ',
      ['0'],
      ' ',
      ['1'],
      ' and ',
      ['2'],
      ' ',
      ['3'],
      ' will be refunded to your wallet due to selected price range.',
    ],
    Auto: 'Auto',
    'Auto Router': 'Auto Router',
    'Available to deposit: {0}': ['Available to deposit: ', ['0']],
    'Balance:': 'Balance:',
    'Balance: {0} {1}': ['Balance: ', ['0'], ' ', ['1']],
    'Best for exotic pairs.': 'Best for exotic pairs.',
    'Best for most pairs.': 'Best for most pairs.',
    'Best for stable pairs.': 'Best for stable pairs.',
    'Best for very stable pairs.': 'Best for very stable pairs.',
    'Blocked address': 'Blocked address',
    Bridge: 'Bridge',
    "By adding liquidity you'll earn 0.3% of all trades on this pair proportional to your share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.":
      "By adding liquidity you'll earn 0.3% of all trades on this pair proportional to your share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.",
    'By adding this list you are implicitly trusting that the data is correct. Anyone can create a list, including creating fake versions of existing lists and lists that claim to represent projects that do not have one.':
      'By adding this list you are implicitly trusting that the data is correct. Anyone can create a list, including creating fake versions of existing lists and lists that claim to represent projects that do not have one.',
    'By connecting a wallet, you agree to L7DEX Labs’ <0>Terms of Service</0> and acknowledge that you have read and understand the L7DEX <1>Protocol Disclaimer</1>.':
      'By connecting a wallet, you agree to L7DEX Labs’ <0>Terms of Service</0> and acknowledge that you have read and understand the L7DEX <1>Protocol Disclaimer</1>.',
    Canceled: 'Canceled',
    Change: 'Change',
    Charts: 'Charts',
    'Check network status': 'Check network status',
    'Check out our v3 LP walkthrough and migration guides.': 'Check out our v3 LP walkthrough and migration guides.',
    Claim: 'Claim',
    'Claim <0/> for {0}': ['Claim <0/> for ', ['0']],
    'Claim UNI Token': 'Claim UNI Token',
    'Claim UNI reward for {0}': ['Claim UNI reward for ', ['0']],
    'Claim fees': 'Claim fees',
    'Claim your UNI tokens': 'Claim your UNI tokens',
    Claimed: 'Claimed',
    'Claimed UNI!': 'Claimed UNI!',
    'Claimed!': 'Claimed!',
    Claiming: 'Claiming',
    'Claiming UNI': 'Claiming UNI',
    'Claiming {0} UNI': ['Claiming ', ['0'], ' UNI'],
    'Clear All': 'Clear All',
    'Clear all': 'Clear all',
    Close: 'Close',
    Closed: 'Closed',
    Collect: 'Collect',
    'Collect as WETH': 'Collect as WETH',
    'Collect fees': 'Collect fees',
    'Collect {0}/{1} fees': ['Collect ', ['0'], '/', ['1'], ' fees'],
    Collected: 'Collected',
    Collecting: 'Collecting',
    'Collecting fees': 'Collecting fees',
    'Collecting fees will withdraw currently available fees for you.':
      'Collecting fees will withdraw currently available fees for you.',
    'Common bases': 'Common bases',
    Confirm: 'Confirm',
    'Confirm Supply': 'Confirm Supply',
    'Confirm Swap': 'Confirm Swap',
    'Confirm this transaction in your wallet': 'Confirm this transaction in your wallet',
    'Confirm transaction in wallet': 'Confirm transaction in wallet',
    'Connect Wallet': 'Connect Wallet',
    'Connect a wallet': 'Connect a wallet',
    'Connect to a wallet to find pools': 'Connect to a wallet to find pools',
    'Connect to a wallet to view your liquidity.': 'Connect to a wallet to view your liquidity.',
    'Connected with {name}': ['Connected with ', ['name']],
    Copied: 'Copied',
    'Copy Address': 'Copy Address',
    'Create Pool & Supply': 'Create Pool & Supply',
    'Create Proposal': 'Create Proposal',
    'Create a pair': 'Create a pair',
    'Create a pool': 'Create a pool',
    'Create an issue on GitHub': 'Create an issue on GitHub',
    'Create pool and add {0}/{1} V3 liquidity': ['Create pool and add ', ['0'], '/', ['1'], ' V3 liquidity'],
    'Create pool.': 'Create pool.',
    'Create {0}/{1} V3 pool': ['Create ', ['0'], '/', ['1'], ' V3 pool'],
    'Current price': 'Current price',
    'Current {0} Price:': ['Current ', ['0'], ' Price:'],
    'Custom Tokens': 'Custom Tokens',
    'Dark Theme': 'Dark Theme',
    Defeated: 'Defeated',
    'Delegate Votes': 'Delegate Votes',
    'Delegate voting power to {0}': ['Delegate voting power to ', ['0']],
    'Delegated to:': 'Delegated to:',
    'Delegating votes': 'Delegating votes',
    Deposit: 'Deposit',
    'Deposit Amounts': 'Deposit Amounts',
    'Deposit Assets': 'Deposit Assets',
    'Deposit UNI-V2 LP Tokens': 'Deposit UNI-V2 LP Tokens',
    'Deposit liquidity': 'Deposit liquidity',
    'Deposited liquidity:': 'Deposited liquidity:',
    'Deposited {0} UNI-V2': ['Deposited ', ['0'], ' UNI-V2'],
    'Depositing Liquidity': 'Depositing Liquidity',
    Description: 'Description',
    Detailed: 'Detailed',
    Details: 'Details',
    Disconnect: 'Disconnect',
    Discord: 'Discord',
    Dismiss: 'Dismiss',
    Docs: 'Docs',
    'Don’t see one of your v2 positions? <0>Import it.</0>': 'Don’t see one of your v2 positions? <0>Import it.</0>',
    Edit: 'Edit',
    'Efficiency Comparison': 'Efficiency Comparison',
    'Enter a percent': 'Enter a percent',
    'Enter a recipient': 'Enter a recipient',
    'Enter a valid slippage percentage': 'Enter a valid slippage percentage',
    'Enter an address to trigger a UNI claim. If the address has any claimable UNI it will be sent to them on submission.':
      'Enter an address to trigger a UNI claim. If the address has any claimable UNI it will be sent to them on submission.',
    'Enter an amount': 'Enter an amount',
    'Enter valid list location': 'Enter valid list location',
    'Enter valid token address': 'Enter valid token address',
    Error: 'Error',
    'Error connecting': 'Error connecting',
    'Error connecting. Try refreshing the page.': 'Error connecting. Try refreshing the page.',
    'Error importing list': 'Error importing list',
    Executed: 'Executed',
    'Expanded results from inactive Token Lists': 'Expanded results from inactive Token Lists',
    'Expert Mode': 'Expert Mode',
    'Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result in bad rates and lost funds.':
      'Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result in bad rates and lost funds.',
    Expired: 'Expired',
    Explorer: 'Explorer',
    'Fee Tier': 'Fee Tier',
    'Fee tier': 'Fee tier',
    For: 'For',
    From: 'From',
    'From (at most)': 'From (at most)',
    'Full Range': 'Full Range',
    'Full range positions may earn less fees than concentrated positions. Learn more <0>here</0>.':
      'Full range positions may earn less fees than concentrated positions. Learn more <0>here</0>.',
    'Get support on Discord': 'Get support on Discord',
    'Help Center': 'Help Center',
    Hide: 'Hide',
    'High Price Impact': 'High Price Impact',
    'How this app uses APIs': 'How this app uses APIs',
    'I understand': 'I understand',
    'If you purchase a token from this list, you may not be able to sell it back.':
      'If you purchase a token from this list, you may not be able to sell it back.',
    Import: 'Import',
    'Import List': 'Import List',
    'Import Pool': 'Import Pool',
    'Import V2 Pool': 'Import V2 Pool',
    'Import at your own risk': 'Import at your own risk',
    'In range': 'In range',
    'Increase Liquidity': 'Increase Liquidity',
    'Initial prices and pool share': 'Initial prices and pool share',
    'Initializing...': 'Initializing...',
    'Input is estimated. You will sell at most <0>{0} {1}</0> or the transaction will revert.': [
      'Input is estimated. You will sell at most <0>',
      ['0'],
      ' ',
      ['1'],
      '</0> or the transaction will revert.',
    ],
    'Install Metamask': 'Install Metamask',
    'Insufficient liquidity for this trade.': 'Insufficient liquidity for this trade.',
    'Insufficient {0} balance': ['Insufficient ', ['0'], ' balance'],
    'Interface Settings': 'Interface Settings',
    'Invalid pair': 'Invalid pair',
    'Invalid pair.': 'Invalid pair.',
    'Invalid price input': 'Invalid price input',
    'Invalid range selected. The min price must be lower than the max price.':
      'Invalid range selected. The min price must be lower than the max price.',
    'Invalid recipient': 'Invalid recipient',
    Language: 'Language',
    Learn: 'Learn',
    'Learn More': 'Learn More',
    'Learn about providing liquidity': 'Learn about providing liquidity',
    'Learn more': 'Learn more',
    'Legal & Privacy': 'Legal & Privacy',
    'Light Theme': 'Light Theme',
    "L7DEX Labs' Terms of Service": "L7DEX Labs' Terms of Service",
    'L7DEX available in: <0>{0}</0>': ['L7DEX available in: <0>', ['0'], '</0>'],
    'L7DEX on {0}': ['L7DEX on ', ['0']],
    Liquidity: 'Liquidity',
    'Liquidity Provider Fee': 'Liquidity Provider Fee',
    'Liquidity data not available.': 'Liquidity data not available.',
    'Liquidity provider rewards': 'Liquidity provider rewards',
    'Liquidity providers earn a 0.3% fee on all trades proportional to their share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.':
      'Liquidity providers earn a 0.3% fee on all trades proportional to their share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.',
    Lists: 'Lists',
    Loaded: 'Loaded',
    Loading: 'Loading',
    Manage: 'Manage',
    'Manage Liquidity in Rewards Pool': 'Manage Liquidity in Rewards Pool',
    'Manage Token Lists': 'Manage Token Lists',
    'Manage this pool.': 'Manage this pool.',
    Max: 'Max',
    'Max Price': 'Max Price',
    'Max price': 'Max price',
    'Max:': 'Max:',
    'Maximum sent': 'Maximum sent',
    Menu: 'Menu',
    Migrate: 'Migrate',
    'Migrate Liquidity': 'Migrate Liquidity',
    'Migrate Liquidity to V3': 'Migrate Liquidity to V3',
    'Migrate {0}/{1} liquidity to V3': ['Migrate ', ['0'], '/', ['1'], ' liquidity to V3'],
    Migrating: 'Migrating',
    'Min Price': 'Min Price',
    'Min price': 'Min price',
    'Min:': 'Min:',
    'Minimum received': 'Minimum received',
    'Missing dependencies': 'Missing dependencies',
    More: 'More',
    'Network Warning': 'Network Warning',
    'New Position': 'New Position',
    'No V2 Liquidity found.': 'No V2 Liquidity found.',
    'No active pools': 'No active pools',
    'No data': 'No data',
    'No description.': 'No description.',
    'No liquidity found.': 'No liquidity found.',
    'No pool found.': 'No pool found.',
    'No proposals found.': 'No proposals found.',
    'No results found.': 'No results found.',
    'Not created': 'Not created',
    OFF: 'OFF',
    ON: 'ON',
    'ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.': 'ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.',
    Off: 'Off',
    On: 'On',
    'Once you are happy with the rate click supply to review.':
      'Once you are happy with the rate click supply to review.',
    'Only UNI votes that were self delegated or delegated to another address before block {0} are eligible for voting.':
      [
        'Only UNI votes that were self delegated or delegated to another address before block ',
        ['0'],
        ' are eligible for voting.',
      ],
    'Oops! An unknown error occurred. Please refresh the page, or visit from another browser or device.':
      'Oops! An unknown error occurred. Please refresh the page, or visit from another browser or device.',
    'Optimism Gateway': 'Optimism Gateway',
    'Optimistic Ethereum is in Beta and may experience downtime. Optimism expects planned downtime to upgrade the network in the near future. During downtime, your position will not earn fees and you will be unable to remove liquidity. <0>Read more.</0>':
      'Optimistic Ethereum is in Beta and may experience downtime. Optimism expects planned downtime to upgrade the network in the near future. During downtime, your position will not earn fees and you will be unable to remove liquidity. <0>Read more.</0>',
    'Optimistic Etherscan': 'Optimistic Etherscan',
    'Out of range': 'Out of range',
    'Output is estimated. If the price changes by more than {0}% your transaction will revert.': [
      'Output is estimated. If the price changes by more than ',
      ['0'],
      '% your transaction will revert.',
    ],
    'Output is estimated. You will receive at least <0>{0} {1}</0> or the transaction will revert.': [
      'Output is estimated. You will receive at least <0>',
      ['0'],
      ' ',
      ['1'],
      '</0> or the transaction will revert.',
    ],
    'Output will be sent to <0>{0}</0>': ['Output will be sent to <0>', ['0'], '</0>'],
    Owner: 'Owner',
    'Participating pools': 'Participating pools',
    Pending: 'Pending',
    'Please confirm you would like to remove this list by typing REMOVE':
      'Please confirm you would like to remove this list by typing REMOVE',
    'Please connect to Layer 1 Ethereum': 'Please connect to Layer 1 Ethereum',
    'Please connect to the appropriate Ethereum network.': 'Please connect to the appropriate Ethereum network.',
    'Please type the word "{confirmWord}" to enable expert mode.': [
      'Please type the word "',
      ['confirmWord'],
      '" to enable expert mode.',
    ],
    Pool: 'Pool',
    'Pool Found!': 'Pool Found!',
    'Pool Rate': 'Pool Rate',
    'Pool rate': 'Pool rate',
    'Pool tokens in rewards pool:': 'Pool tokens in rewards pool:',
    'Pooled {0}:': ['Pooled ', ['0'], ':'],
    'Pools Overview': 'Pools Overview',
    Preview: 'Preview',
    Price: 'Price',
    'Price Difference:': 'Price Difference:',
    'Price Impact': 'Price Impact',
    'Price Impact Too High': 'Price Impact Too High',
    'Price Updated': 'Price Updated',
    'Price range': 'Price range',
    'Price:': 'Price:',
    'Prices and pool share': 'Prices and pool share',
    Proposal: 'Proposal',
    'Proposal Submitted': 'Proposal Submitted',
    'Proposal Title': 'Proposal Title',
    Proposals: 'Proposals',
    'Proposals submitted by community members will appear here.':
      'Proposals submitted by community members will appear here.',
    'Proposed Action': 'Proposed Action',
    Proposer: 'Proposer',
    'Protocol Disclaimer': 'Protocol Disclaimer',
    Queued: 'Queued',
    Rates: 'Rates',
    'Read more about providing liquidity': 'Read more about providing liquidity',
    'Read more about unsupported assets': 'Read more about unsupported assets',
    'Recent Transactions': 'Recent Transactions',
    Recipient: 'Recipient',
    Remove: 'Remove',
    'Remove <0/> and <1/>': 'Remove <0/> and <1/>',
    'Remove Amount': 'Remove Amount',
    'Remove Delegate': 'Remove Delegate',
    'Remove Liquidity': 'Remove Liquidity',
    'Remove list': 'Remove list',
    'Removing {0} {1} and {2} {3}': ['Removing ', ['0'], ' ', ['1'], ' and ', ['2'], ' ', ['3']],
    'Removing {0} {1} and{2} {3}': ['Removing ', ['0'], ' ', ['1'], ' and', ['2'], ' ', ['3']],
    'Request Features': 'Request Features',
    Return: 'Return',
    'Search name or paste address': 'Search name or paste address',
    'Select Pair': 'Select Pair',
    'Select a network': 'Select a network',
    'Select a token': 'Select a token',
    'Select a token to find your liquidity.': 'Select a token to find your liquidity.',
    'Select an action': 'Select an action',
    'Selected Range': 'Selected Range',
    Self: 'Self',
    'Self Delegate': 'Self Delegate',
    'Set Price Range': 'Set Price Range',
    'Set Starting Price': 'Set Starting Price',
    'Share of Pool': 'Share of Pool',
    'Share of Pool:': 'Share of Pool:',
    'Show Portis': 'Show Portis',
    'Show closed positions': 'Show closed positions',
    Simple: 'Simple',
    'Slippage tolerance': 'Slippage tolerance',
    'Some assets are not available through this interface because they may not work well with the smart contracts or we are unable to allow trading for legal reasons.':
      'Some assets are not available through this interface because they may not work well with the smart contracts or we are unable to allow trading for legal reasons.',
    'Something went wrong': 'Something went wrong',
    Status: 'Status',
    'Step 1. Get UNI-V2 Liquidity tokens': 'Step 1. Get UNI-V2 Liquidity tokens',
    'Submit new proposal': 'Submit new proposal',
    'Submitting Proposal': 'Submitting Proposal',
    'Submitting Vote': 'Submitting Vote',
    Succeeded: 'Succeeded',
    Success: 'Success',
    Supply: 'Supply',
    'Supplying {0} {1} and {2} {3}': ['Supplying ', ['0'], ' ', ['1'], ' and ', ['2'], ' ', ['3']],
    Swap: 'Swap',
    'Swap <0/> for exactly <1/>': 'Swap <0/> for exactly <1/>',
    'Swap Anyway': 'Swap Anyway',
    'Swap exactly <0/> for <1/>': 'Swap exactly <0/> for <1/>',
    'Swap failed: {0}': ['Swap failed: ', ['0']],
    'Swapping {0} {1} for {2} {3}': ['Swapping ', ['0'], ' ', ['1'], ' for ', ['2'], ' ', ['3']],
    'The % you will earn in fees.': 'The % you will earn in fees.',
    'The L7DEX invariant x*y=k was not satisfied by the swap. This usually means one of the tokens you are swapping incorporates custom behavior on transfer.':
      'The L7DEX invariant x*y=k was not satisfied by the swap. This usually means one of the tokens you are swapping incorporates custom behavior on transfer.',
    'The app fetches blockchain data from The Graph’s hosted service.':
      'The app fetches blockchain data from The Graph’s hosted service.',
    'The app fetches on-chain data and constructs contract calls with an Infura API.':
      'The app fetches on-chain data and constructs contract calls with an Infura API.',
    'The app fetches the optimal trade route from a L7DEX Labs server.':
      'The app fetches the optimal trade route from a L7DEX Labs server.',
    'The app logs anonymized usage statistics in order to improve over time.':
      'The app logs anonymized usage statistics in order to improve over time.',
    'The app securely collects your wallet address and shares it with TRM Labs Inc. for risk and compliance reasons.':
      'The app securely collects your wallet address and shares it with TRM Labs Inc. for risk and compliance reasons.',
    'The input token cannot be transferred. There may be an issue with the input token.':
      'The input token cannot be transferred. There may be an issue with the input token.',
    'The market price is outside your specified price range. Single-asset deposit only.':
      'The market price is outside your specified price range. Single-asset deposit only.',
    'The output token cannot be transferred. There may be an issue with the output token.':
      'The output token cannot be transferred. There may be an issue with the output token.',
    'The price of this pool is outside of your selected range. Your position is not currently earning fees.':
      'The price of this pool is outside of your selected range. Your position is not currently earning fees.',
    'The price of this pool is within your selected range. Your position is currently earning fees.':
      'The price of this pool is within your selected range. Your position is currently earning fees.',
    'The ratio of tokens you add will set the price of this pool.':
      'The ratio of tokens you add will set the price of this pool.',
    'The transaction could not be sent because the deadline has passed. Please check that your transaction deadline is not too low.':
      'The transaction could not be sent because the deadline has passed. Please check that your transaction deadline is not too low.',
    'There is no liquidity data.': 'There is no liquidity data.',
    'These tokens are commonly paired with other tokens.': 'These tokens are commonly paired with other tokens.',
    'This app uses the following third-party APIs:': 'This app uses the following third-party APIs:',
    'This pool must be initialized before you can add liquidity. To initialize, select a starting price for the pool. Then, enter your liquidity price range and deposit amount. Gas fees will be higher than usual due to the initialization transaction.':
      'This pool must be initialized before you can add liquidity. To initialize, select a starting price for the pool. Then, enter your liquidity price range and deposit amount. Gas fees will be higher than usual due to the initialization transaction.',
    'This route optimizes your price by considering split routes, multiple hops, and gas costs.':
      'This route optimizes your price by considering split routes, multiple hops, and gas costs.',
    "This token doesn't appear on the active token list(s). Make sure this is the token that you want to trade.":
      "This token doesn't appear on the active token list(s). Make sure this is the token that you want to trade.",
    'This token is not supported in the L7DEX Labs app': 'This token is not supported in the L7DEX Labs app',
    'This tool will safely migrate your {0} liquidity to V3. The process is completely trustless thanks to the': [
      'This tool will safely migrate your ',
      ['0'],
      ' liquidity to V3. The process is completely trustless thanks to the',
    ],
    'This transaction will not succeed due to price movement. Try increasing your slippage tolerance.':
      'This transaction will not succeed due to price movement. Try increasing your slippage tolerance.',
    'This transaction will not succeed either due to price movement or fee on transfer. Try increasing your slippage tolerance.':
      'This transaction will not succeed either due to price movement or fee on transfer. Try increasing your slippage tolerance.',
    'Tip: Custom tokens are stored locally in your browser': 'Tip: Custom tokens are stored locally in your browser',
    To: 'To',
    'To (at least)': 'To (at least)',
    'To start trading on {0}, first bridge your assets from L1 to L2. Please treat this as a beta release and learn about the risks before using {1}.':
      [
        'To start trading on ',
        ['0'],
        ', first bridge your assets from L1 to L2. Please treat this as a beta release and learn about the risks before using ',
        ['1'],
        '.',
      ],
    'Token not supported': 'Token not supported',
    Tokens: 'Tokens',
    'Tokens from inactive lists. Import specific tokens below or click Manage to activate more lists.':
      'Tokens from inactive lists. Import specific tokens below or click Manage to activate more lists.',
    'Top pools': 'Top pools',
    'Total Supply': 'Total Supply',
    'Total deposited': 'Total deposited',
    'Total deposits': 'Total deposits',
    'Trade Route': 'Trade Route',
    'Transaction Details': 'Transaction Details',
    'Transaction Settings': 'Transaction Settings',
    'Transaction Submitted': 'Transaction Submitted',
    'Transaction completed in': 'Transaction completed in',
    'Transaction deadline': 'Transaction deadline',
    'Transaction rejected.': 'Transaction rejected.',
    'Transfer Token': 'Transfer Token',
    'Try Again': 'Try Again',
    'Turn On Expert Mode': 'Turn On Expert Mode',
    'UNI has arrived': 'UNI has arrived',
    'UNI in circulation:': 'UNI in circulation:',
    'UNI price:': 'UNI price:',
    "UNI-V2 LP tokens are required. Once you've added liquidity to the {0}-{1} pool you can stake your liquidity tokens on this page.":
      [
        "UNI-V2 LP tokens are required. Once you've added liquidity to the ",
        ['0'],
        '-',
        ['1'],
        ' pool you can stake your liquidity tokens on this page.',
      ],
    'UNI-V2 {0}-{1}': ['UNI-V2 ', ['0'], '-', ['1']],
    'Unclaimed UNI': 'Unclaimed UNI',
    'Unclaimed fees': 'Unclaimed fees',
    'Unclaimed:': 'Unclaimed:',
    Undetermined: 'Undetermined',
    'Unexpected error. Could not estimate gas for the swap.': 'Unexpected error. Could not estimate gas for the swap.',
    'Unexpected issue with estimating the gas. Please try again.':
      'Unexpected issue with estimating the gas. Please try again.',
    'Unknown Source': 'Unknown Source',
    'Unknown error{0}. Try increasing your slippage tolerance.': [
      'Unknown error',
      ['0'],
      '. Try increasing your slippage tolerance.',
    ],
    'Unlock Votes': 'Unlock Votes',
    'Unlock Voting': 'Unlock Voting',
    'Unlocking Votes': 'Unlocking Votes',
    'Unsupported Asset': 'Unsupported Asset',
    'Unsupported Assets': 'Unsupported Assets',
    Untitled: 'Untitled',
    Unwrap: 'Unwrap',
    'Unwrap <0/> to ETH': 'Unwrap <0/> to ETH',
    'Update Delegation': 'Update Delegation',
    'Update list': 'Update list',
    User: 'User',
    V2: 'V2',
    'V2 is not available on Layer 2. Switch to Layer 1 Ethereum.':
      'V2 is not available on Layer 2. Switch to Layer 1 Ethereum.',
    'V2 liquidity': 'V2 liquidity',
    V3: 'V3',
    'V3 {0} Price:': ['V3 ', ['0'], ' Price:'],
    'View UNI Analytics': 'View UNI Analytics',
    'View V2 Liquidity': 'View V2 Liquidity',
    'View accrued fees and analytics<0>↗</0>': 'View accrued fees and analytics<0>↗</0>',
    'View list': 'View list',
    'View on Etherscan': 'View on Etherscan',
    'View on Explorer': 'View on Explorer',
    'View transaction on Explorer': 'View transaction on Explorer',
    Vote: 'Vote',
    'Vote Against': 'Vote Against',
    'Vote For': 'Vote For',
    'Vote against proposal {proposalId}': ['Vote against proposal ', ['proposalId']],
    'Vote against proposal {proposalKey}': ['Vote against proposal ', ['proposalKey']],
    'Vote against proposal {proposalKey} with reason "{0}"': [
      'Vote against proposal ',
      ['proposalKey'],
      ' with reason "',
      ['0'],
      '"',
    ],
    'Vote for proposal {proposalId}': ['Vote for proposal ', ['proposalId']],
    'Vote for proposal {proposalKey}': ['Vote for proposal ', ['proposalKey']],
    'Vote for proposal {proposalKey} with reason "{0}"': [
      'Vote for proposal ',
      ['proposalKey'],
      ' with reason "',
      ['0'],
      '"',
    ],
    'Vote to abstain on proposal {proposalId}': ['Vote to abstain on proposal ', ['proposalId']],
    'Vote to abstain on proposal {proposalKey}': ['Vote to abstain on proposal ', ['proposalKey']],
    'Vote to abstain on proposal {proposalKey} with reason "{0}"': [
      'Vote to abstain on proposal ',
      ['proposalKey'],
      ' with reason "',
      ['0'],
      '"',
    ],
    'Voting ended {0}': ['Voting ended ', ['0']],
    'Voting ends approximately {0}': ['Voting ends approximately ', ['0']],
    'Voting starts approximately {0}': ['Voting starts approximately ', ['0']],
    'Waiting For Confirmation': 'Waiting For Confirmation',
    'Wallet Address or ENS name': 'Wallet Address or ENS name',
    'Weekly Rewards': 'Weekly Rewards',
    'Welcome to team Unicorn :)': 'Welcome to team Unicorn :)',
    'When you claim without withdrawing your liquidity remains in the mining pool.':
      'When you claim without withdrawing your liquidity remains in the mining pool.',
    'When you withdraw, the contract will automagically claim UNI on your behalf!':
      'When you withdraw, the contract will automagically claim UNI on your behalf!',
    'When you withdraw, your UNI is claimed and your liquidity is removed from the mining pool.':
      'When you withdraw, your UNI is claimed and your liquidity is removed from the mining pool.',
    Withdraw: 'Withdraw',
    'Withdraw & Claim': 'Withdraw & Claim',
    'Withdraw deposited liquidity': 'Withdraw deposited liquidity',
    'Withdrawing {0} UNI-V2': ['Withdrawing ', ['0'], ' UNI-V2'],
    'Withdrew UNI-V2!': 'Withdrew UNI-V2!',
    Wrap: 'Wrap',
    'Wrap <0/> to WETH': 'Wrap <0/> to WETH',
    'Wrong Network': 'Wrong Network',
    'You already have an active or pending proposal': 'You already have an active or pending proposal',
    'You are creating a pool': 'You are creating a pool',
    'You are the first liquidity provider.': 'You are the first liquidity provider.',
    'You can either vote on each proposal yourself or delegate your votes to a third party.':
      'You can either vote on each proposal yourself or delegate your votes to a third party.',
    'You can now trade {0}': ['You can now trade ', ['0']],
    "You don't have enough votes to submit a proposal": "You don't have enough votes to submit a proposal",
    'You don’t have liquidity in this pool yet.': 'You don’t have liquidity in this pool yet.',
    'You may have lost your network connection, or {label} might be down right now.': [
      'You may have lost your network connection, or ',
      ['label'],
      ' might be down right now.',
    ],
    'You may have lost your network connection.': 'You may have lost your network connection.',
    'You must connect an account.': 'You must connect an account.',
    'You must give the L7DEX smart contracts permission to use your {0}. You only have to do this once per token.': [
      'You must give the L7DEX smart contracts permission to use your ',
      ['0'],
      '. You only have to do this once per token.',
    ],
    'You must have {formattedProposalThreshold} votes to submit a proposal': [
      'You must have ',
      ['formattedProposalThreshold'],
      ' votes to submit a proposal',
    ],
    'You will receive': 'You will receive',
    'Your UNI Breakdown': 'Your UNI Breakdown',
    'Your V3 liquidity positions will appear here.': 'Your V3 liquidity positions will appear here.',
    'Your liquidity': 'Your liquidity',
    'Your liquidity deposits': 'Your liquidity deposits',
    'Your pool share:': 'Your pool share:',
    'Your position': 'Your position',
    'Your position has 0 liquidity, and is not earning fees.':
      'Your position has 0 liquidity, and is not earning fees.',
    'Your position will appear here.': 'Your position will appear here.',
    'Your position will be 100% composed of {0} at this price': [
      'Your position will be 100% composed of ',
      ['0'],
      ' at this price',
    ],
    'Your position will be 100% {0} at this price.': ['Your position will be 100% ', ['0'], ' at this price.'],
    'Your position will not earn fees or be used in trades until the market price moves into your range.':
      'Your position will not earn fees or be used in trades until the market price moves into your range.',
    'Your positions': 'Your positions',
    'Your rate': 'Your rate',
    'Your total pool tokens:': 'Your total pool tokens:',
    'Your transaction cost will be much higher as it includes the gas to create the pool.':
      'Your transaction cost will be much higher as it includes the gas to create the pool.',
    'Your transaction may be frontrun': 'Your transaction may be frontrun',
    'Your transaction may fail': 'Your transaction may fail',
    'Your transaction will revert if it is pending for more than this period of time.':
      'Your transaction will revert if it is pending for more than this period of time.',
    'Your transaction will revert if the price changes unfavorably by more than this percentage.':
      'Your transaction will revert if the price changes unfavorably by more than this percentage.',
    'Your transactions will appear here...': 'Your transactions will appear here...',
    confirm: 'confirm',
    'for {0}': ['for ', ['0']],
    'has socks emoji': 'has socks emoji',
    'here.': 'here.',
    'https:// or ipfs:// or ENS name': 'https:// or ipfs:// or ENS name',
    minutes: 'minutes',
    'via {0}': ['via ', ['0']],
    'via {0} token list': ['via ', ['0'], ' token list'],
    '{0, plural, one {Import token} other {Import tokens}}': [
      ['0', 'plural', { one: 'Import token', other: 'Import tokens' }],
    ],
    '{0}': [['0']],
    '{0} %': [['0'], ' %'],
    '{0} <0/> per <1/>': [['0'], ' <0/> per <1/>'],
    '{0} Custom Tokens': [['0'], ' Custom Tokens'],
    '{0} Deposited': [['0'], ' Deposited'],
    '{0} ETH': [['0'], ' ETH'],
    '{0} Fees Earned:': [['0'], ' Fees Earned:'],
    '{0} Pending': [['0'], ' Pending'],
    '{0} UNI': [['0'], ' UNI'],
    '{0} UNI / week': [['0'], ' UNI / week'],
    '{0} UNI-V2': [['0'], ' UNI-V2'],
    '{0} UNI-V2 LP tokens available': [['0'], ' UNI-V2 LP tokens available'],
    '{0} Votes': [['0'], ' Votes'],
    '{0} per {1}': [['0'], ' per ', ['1']],
    '{0} tokens': [['0'], ' tokens'],
    '{0} {1} Price:': [['0'], ' ', ['1'], ' Price:'],
    '{0} • Added by user': [['0'], ' • Added by user'],
    '{0}%': [['0'], '%'],
    '{0}% fee tier': [['0'], '% fee tier'],
    '{0}% select': [['0'], '% select'],
    '{0}-{1} Liquidity Mining': [['0'], '-', ['1'], ' Liquidity Mining'],
    '{0}/{1} Burned': [['0'], '/', ['1'], ' Burned'],
    '{0}/{1} LP NFT': [['0'], '/', ['1'], ' LP NFT'],
    '{0}/{1} LP Tokens': [['0'], '/', ['1'], ' LP Tokens'],
    '{SOCKS_AMOUNT} UNI': [['SOCKS_AMOUNT'], ' UNI'],
    '{USER_AMOUNT} UNI': [['USER_AMOUNT'], ' UNI'],
    '{activeTokensOnThisChain} tokens': [['activeTokensOnThisChain'], ' tokens'],
    '{percentForSlider}%': [['percentForSlider'], '%'],
    '{tokenB} per {tokenA}': [['tokenB'], ' per ', ['tokenA']],
    '~$ <0/>': '~$ <0/>',
    '← Back to Pools Overview': '← Back to Pools Overview',
    NavTrade: 'Trade',
    NavDashboard: 'Dashboard',
    NavEarn: 'Earn',
    NavBuy: 'Buy',
    NavDocs: 'Docs',
    NavFaucet: 'Faucet',
    NavProfile: 'Profile',
    NavBridge: 'Bridge',
    NavMore: 'More',
    NavDocument: 'Document',
    NavLaunchpad: 'Launchpad',
    NavSwap: 'Swap',
    NavFutures: 'Futures',
    NavAuditReport: 'Audit Report',
    FooterOperational: 'Operational',
    FooterTermsOfUse: 'Terms of Use',
    FooterPrivacyPolicy: 'Privacy Policy',
  },
}
